function Eye({ open, onClick }) {
  return (
    <svg
      style={{
        opacity: open() ? 1.0 : 0.1,
      }} 
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path d="M10.0005 13.2349C11.3812 13.2349 12.5005 12.1156 12.5005 10.7349C12.5005 9.35415 11.3812 8.23486 10.0005 8.23486C8.61978 8.23486 7.50049 9.35415 7.50049 10.7349C7.50049 12.1156 8.61978 13.2349 10.0005 13.2349Z" fill="#525252"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.664743 11.3252C0.51788 10.9436 0.518007 10.5205 0.665098 10.1389C2.10927 6.39274 5.74378 3.73486 9.99908 3.73486C14.2565 3.73486 17.8925 6.39537 19.3352 10.1445C19.4821 10.5261 19.4819 10.9492 19.3348 11.3308C17.8907 15.077 14.2562 17.7349 10.0009 17.7349C5.74346 17.7349 2.10746 15.0744 0.664743 11.3252ZM14.0009 10.7349C14.0009 12.944 12.21 14.7349 10.0009 14.7349C7.79172 14.7349 6.00086 12.944 6.00086 10.7349C6.00086 8.52572 7.79172 6.73486 10.0009 6.73486C12.21 6.73486 14.0009 8.52572 14.0009 10.7349Z" fill="#525252"/>
    </svg>
  )
}

export default Eye
