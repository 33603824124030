import { onMount } from 'solid-js'

import Form from './Form'
import Logo from './Logo'
import { ampli } from '@analytics'

import './App.css'

const {
  VITE_AMPLITUDE_API_KEY,
  VITE_AMPLITUDE_ENABLED,
} = import.meta.env

const App = () => {
  onMount(() => {
    (async () => {
      if (Boolean(VITE_AMPLITUDE_ENABLED ?? false)) {
        try {
          await ampli.load({
            client: {
              apiKey: VITE_AMPLITUDE_API_KEY,
              configuration: {
                defaultTracking: true,
                flushMaxRetries: 1,
                disableCookies: true,
              }
            }
          })
          await ampli.identify()
          await ampli.formStarted()
          console.log('Started Amplitude tracking.')
        } catch(e) {
          console.log('waaaaaaaaaaa')
          ampli.setOptOut(true)
          console.error('Amplitude error: ', e.message)
        }
      }
    })();
  })

  return (
    <main>
      <article>
        <Logo />
        <p className='login'>
          ¿Ya tienes cuenta? <a href="https://portal.n1co.shop/" target="_blank">
            Ingresa aquí.
          </a>
        </p>
        <h1>
          Completa el siguiente formulario y empieza a cobrar de forma digital con n1co
        </h1>
        <Form />
        <p className='tos'>
          Al presionar "Registrar", aceptas los 
          <a href="https://n1co.com/terminos-y-condiciones-business/" target="_blank">
            Términos y Condiciones
          </a>
         <br />de N1co.
        </p>
      </article>
      <aside />
    </main>
  ) 
}

export default App
