import { createEffect, createSignal, Show } from 'solid-js'
import { ampli } from '@analytics'
import { Portal } from 'solid-js/web'

import { useForm } from './hooks'
import { Input, PhoneInput } from './components'
import HeartImageUrl from './assets/wservices-03.png'
import './Form.css'

const schema = {
  storename: 'required|store_name|min:4|max:100',
  country: 'required|in_array:hn,sv',
  name: 'required|name|max:0|max:100',
  lastname: 'required|last_name|max:0|max:100',
  phone: 'required|phone',
  email: 'required|email',
  email2: 'required|field_equals:email',
  password: 'required|string|min:8|password',
  password2: 'required|field_equals:password'
}

const API_URL = import.meta.env.VITE_API_URL
const REDIRECT_URL = import.meta.env.VITE_REDIRECT_URL

const countryCodeInputOpts = {
  'hn': 'Honduras',
  'sv': 'El Salvador'
}

function Form() {
  const {
    values,
    errors,
    handleChange,
    validate,
    handleSubmit
  } = useForm(schema, {
    country: '',
    storename: '',
    name: '',
    lastname: '',
    phone: '',
    email: '',
    email2: '',
    password: '',
    password2: '',
    salesChannels: [
      "PaymentLink",
      "Storefront"
    ],
  })
  const [formErrors, setFormError] = createSignal({ hasError: false })
  const [isLoading, setIsLoading] = createSignal(false)
  const [isRegistered, setIsRegistered] = createSignal(false)

  // createEffect(() => {
  //   console.log('v', values())
  // })

  const handleBlur = () => { 
    validate()
  }

  const handleFormSubmit = async (formValues) => {
    setIsLoading(true)

    const payload = {
      storeName: formValues.storename,
      firstName: formValues.name,
      lastName: formValues.lastname,
      phone: formValues.phone,
      countryCode: formValues.country,
      email: formValues.email,
      password: formValues.password,
      confirmPassword: formValues.password2,
      salesChannels: [
        "PaymentLink",
        "Storefront"
      ],
    }

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })
      if (response.ok) {
        setIsRegistered(true)
        await ampli.formSubmitted()
        setTimeout(() => {
          window.location = REDIRECT_URL
        }, 5000)
      } else {
        const errorData = await response.json()
        console.error('Failed to submit form:', errorData)
        setFormError({ hasError: true, errors: errorData.errors })
      }
    } catch (error) {
      console.error('API call failed:', error)
    }

    setIsLoading(false)
  }

  const handleFormError = (formErrors) => {
    setFormError(formErrors)
  }

  const handleTrackEvent = ({ target, inputId }) => {
    ampli.clicked({ elementId: inputId, elementValue: target.value })
  }

  const defaultInputProps = {
    values,
    errors,
    onInput: handleChange,
    onChange: handleTrackEvent,
    onBlur: handleBlur
  }

  const isLongEnough = (password) => password?.length >= 8
  const hasUpperCase = (password) => /[A-Z]/.test(password)
  const hasSpecialCharacter = (password) => /[!@#$%^&*]/.test(password)

  return (
    <>
      <form className="form" onSubmit={handleSubmit(handleFormSubmit, handleFormError)}>
        <div className="input-row">
          <Input
            name="storename"
            label="Nombre de la tienda"
            placeholder="Ingresa el nombre de tu tienda"
            {...defaultInputProps}
          />
        </div>
        <div className="input-row">
          <Input
            type="select"
            name="country"
            label="País"
            placeholder="Seleccionar el país"
            options={countryCodeInputOpts}
            {...defaultInputProps}
          />
        </div>
        <div className="input-row">
          <Input
            name="name"
            label="Nombre Completo"
            placeholder="Ingresa tu nombre"
            {...defaultInputProps}
          />
          <Input
            name="lastname"
            label="Apellidos"
            placeholder="Ingresa tu apellido"
            {...defaultInputProps}
          />
        </div>
        <div className="input-row">
          <PhoneInput
            name="phone"
            label="Teléfono"
            onlyCountries={Object.keys(countryCodeInputOpts)}
            values={values}
            {...defaultInputProps}
          />
        </div>
        <div className="input-row">
          <Input
            name="email"
            label="Correo electrónico"
            placeholder="Escribe tu correo"
            {...defaultInputProps}
          />
          <Input
            name="email2"
            label="Confirmar correo electrónico"
            placeholder="Confirma tu correo"
            {...defaultInputProps}
          />
        </div>
        <div>
          <div className="input-row">
            <Input
              type="password"
              name="password"
              label="Crear contraseña"
              placeholder="Ingresa una contraseña"
              {...defaultInputProps}
            />
            <Input
              type="password"
              name="password2"
              label="Confirma la contraseña"
              placeholder="Confirma tu contraseña"
              {...defaultInputProps}
            />
          </div>
          <div className="password-tips">
            <div className={`tip ${isLongEnough(values().password) ? 'match' : ''}`}>
              - Más de 8 caracteres
            </div>
            <div className={`tip ${hasUpperCase(values().password) ? 'match' : ''}`}>
              - 1 Mayúscula
            </div>
            <div className={`tip ${hasSpecialCharacter(values().password) ? 'match' : ''}`}>
              - 1 Caracter especial
            </div>
          </div>
        </div>
        {
          formErrors().hasError ? (
            <div className="form-errors">
              Por favor revisa los valores e intenta nuevamente.
              {Object.values(formErrors().errors ?? {}).length > 0 && (
                <ul>
                  {Object.values(formErrors().errors).map((errors) => (
                    errors.map((error) => <li>{error}</li>)
                  ))}
                </ul>
              )}
            </div>
          ) : null
        }
        <Show
          when={!isLoading()}
          fallback={(
            <button className="loading">
              <div class="lds-ring"><div /><div /><div /><div /></div>
              Cargando
            </button>
          )}
        >
          <button
            type="submit"
            disabled={Object.keys(errors()).length || Object.values(values()).some((v) => !v)}
          >
            Registrar
          </button>
        </Show>
      </form>
      <Show when={isRegistered()}>
        <Portal ref={x => x.id = "success-modal"} mount={document.getElementById('root')}>
          <div class="backdrop" />
          <div class="modal-content">
            <img src={HeartImageUrl} />
            <h1>¡Registro exitoso!</h1>
            <p>Espera un momento mientras te redireccionamos a nuestro portal.</p>
          </div>
        </Portal>
      </Show>
    </>
  ) 
}

export default Form

