import { createSignal } from 'solid-js'
import V from './max-validator'

V.extend(
  'password',
  function (value) {
    const err = { value }
    const regex = new RegExp(/^(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/)
    if (regex.test(value)) {
      return true
    } else {
      return err
    }
  },
  'Contraseña invalida. Asegurate de cumplir con los requisitos listados abajo'
)

V.extend(
  'phone',
  function (value) {
    const err = { value }
    const regex = new RegExp('^[0-9]*$')
    const v = value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/\s]/gi, '')
    if (regex.test(v)) {
      return true
    } else {
      return err
    }
  },
  'Número telefónico invalido'
)

V.extend(
  'store_name',
  function (value) {
    const err = { value }
    const regex = new RegExp(/^[a-zA-Z0-9\s]+$/)
    if (regex.test(value)) {
      return true
    } else {
      return err
    }
  },
  'Nombre de tienda invalido. Solo letras y números son permitidos'
)

V.extend(
  'name',
  function (value) {
    const err = { value }
    const regex = new RegExp(/^[ a-zA-Z\-\’]+$/)
    if (regex.test(value)) {
      return true
    } else {
      return err
    }
  },
  'Nombre invalido'
)

V.extend(
  'last_name',
  function (value) {
    const err = { value }
    const regex = new RegExp(/^[ a-zA-Z\-\’]+$/)
    if (regex.test(value)) {
      return true
    } else {
      return err
    }
  },
  'Apellido invalido'
)

const useForm = (schema, defaultValues) => {
  const [values, setValues] = createSignal(
    defaultValues || Object.keys(schema).reduce((acc, key) => ({ ...acc, [key]: '' }), {})
  )
  const [touched, setTouched] = createSignal({})
  const [errors, setErrors] = createSignal(V.getEmpty())

  const validate = () => {
    const currentValues = values()
    const currentTouched = touched()
    const validationErrors = {}
    const fieldErrors = V.validate(currentValues, schema)

    if (fieldErrors.hasError) {
      for (const key in currentTouched) {
        if (fieldErrors.errors[key]) {
          validationErrors[key] = fieldErrors.errors[key]
        }
      }
    }

    setErrors(validationErrors)
    return validationErrors
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }))
    setTouched(prevTouched => ({
      ...prevTouched,
      [name]: true
    }))
  }

  const handleSubmit = (callback, errorCallback) => (event) => {
    event.preventDefault()
    const currentValues = values()
    const fieldErrors = V.validate(currentValues, schema)

    if (fieldErrors.hasError) {
      errorCallback(fieldErrors)
      return false
    }

    callback(currentValues)
    return true
  }

  return {
    values,
    errors,
    validate,
    handleChange,
    handleSubmit,
  }
}

export default useForm

