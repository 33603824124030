import { createSignal, Show, For } from 'solid-js'
import Eye from './Eye'
import s from './Input.module.css'

function Input({
  label,
  placeholder,
  name,
  type,
  values,
  errors,
  options,
  onChange,
  onInput,
  onBlur,
  inputProps,
}) {
  const [ eye, setEye ] = createSignal(false)

  return (
    <div>
      <label className={s.styles}>
        {label && <span className={s.label}>{label}</span>}
        <div className={s.inputContainer}>
          {
            type === 'select' ? (
              <select
                name={name}
                value={values()[name]}
                onInput={onInput}
                onBlur={onBlur}
                onChange={({ target }) => onChange({ target, inputId: name })}
                {...inputProps}
              >
                { placeholder && (
                  <option value="" disabled selected>
                    {placeholder}
                  </option>
                )}
                {Object.entries(options).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </select>
            ) : (
                <input
                  id={name}
                  type={type ? (type === 'password' && eye() ? 'text' : type) : 'text'}
                  name={name}
                  value={values()[name]}
                  placeholder={placeholder}
                  onInput={onInput}
                  onChange={({ target }) => onChange({ target, inputId: name })}
                  onBlur={onBlur}
                  {...inputProps}
                />
              )
          }
          { type === 'password' && <Eye open={eye} onClick={() => setEye(y => !y)} /> }
        </div>
      </label>
      <Show when={errors()[name]?.length >= 1}>
        <div className={s.errorContainer}>
          <For each={errors()[name]}>
            {(errorMessage) =>
              <p>- {errorMessage}.</p>
            }
          </For>
        </div>
      </Show>
    </div>
  )
}

export default Input

